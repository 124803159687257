import React from "react"

/** components */
import { ColorPalette } from "../Theme"

/** props */
interface Props {
  backgroundColorOne: string
  backgroundColorTwo: string
  badgeTitle: string
  bottomMargin?: number
  buttonName: string
  buttonUrl?: string
  colorPalette: ColorPalette
  colSizeOne: number
  colSizeTwo: number
  fontTextColor: string
  image: string
  imageTitle: string
  subtitle: string
  title: string
  titleTopLeft: string
  imageSubtitle?: string
  imageTitleSubtitle?: string
  asoEffect: string
}

/** const */
const FinishingProductSection: React.FC<Props> = (props) => {
  return (
    <section
      className={`container-fluid text-left p-5 pt-5 pb-5`}
      style={{
        background: "#EFEFEF",
        color: "#365c70",
      }}
    >
      <div className="container">
        <div
          className="contentHeroLeftTitle hidden-sm hidden-md hidden-lg hidden-xl m-0 p-0"
          style={{ fontSize: "24px", color: "#365c70" }}
        >
          Doors & Windows & Cabinets
        </div>

        <hr
          className="hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
            marginBottom: "10px",
            color: "",
          }}
        />


       <div class="container m-0 p-0 mt-3 mb-5 d-none d-sm-block">
          <div class="row">
            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/finishing/1.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>

            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/finishing/2.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>

            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/finishing/3.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>
          </div>
        </div>

       <div class="container m-0 p-0 d-block d-sm-none">
          <img
            data-aos="Fade"
            src="/finishing/2.jpg"
            loading="lazy"
            decoding="async"
            alt=""
            width="100%"
          />
        </div>


        <div className="contentHeroSpace hidden-xs"></div>

        <div className="row">
         <div
           className="contentHeroLeftTitle hidden-sm hidden-md hidden-lg hidden-xl m-0 p-0"
           style={{ fontSize: "18px", color: "#365c70" }}
         >
         </div>

         <div
            className="hidden-sm hidden-md hidden-lg hidden-xl mb-5 p-0"
            style={{ fontSize: "18px", color: "" }}
          >
            Norhart Supply has numerous products to personalize your home or business. We carry products such as doors, windows, cabinets and countertops to make your vision come to life.  These products are the finishing touches to any structure or space

            <p/>
            <hr/>

            <p/>

            <strong>Doors</strong>
            <br/>
            At Norhart Supply, we carry hollow metal, glass and wood doors for the entrance of all parts of a building.  Hollow metal doors come in a variety of sizes for commercial buildings or garage usage.  The glass doors for businesses and commercial buildings to create an open concept feel, allow natural light to travel through rooms and modernize the space.  Wood doors in a variety of colors and sizes to suit your home and dwelling or business.
            <p/>
            <hr/>

            <strong>Windows</strong>
            <br/>
            Norhart Supply has both large, grand windows as well as smaller bedroom and living room windows.  As the idea of open concept spaces broadens, large windows and natural light have become more prominent.  We carry windows to create that open feel and give any space lots of natural light and sunshine.  We also supply windows for smaller spaces, still providing natural light to fill a room.
            <p/>
            <hr/>

            <strong>Cabinets</strong>
            <br/>
            Cabinets are a staple piece in any kitchen or bathroom.  At Norhart Supply, we provide a variety of cabinetry options to suit your needs.  From base cabinets to tall pantry cupboards, Norhart Supply has what you need to build the storage setup of your dreams.
            <p/>
            <hr/>

            <strong>Countertops</strong>
            <br/>
            Norhart Supply provides an array of countertops.  We primarily focus on 2cm granite countertops, ranging in colors from black to multicolor. Whether you’re looking for a dramatic, sleek black countertop, a staple piece like gorgeous white countertops or a tamer countertop with swirls and specs of browns, bronzes, greys and oranges, Norhart Supply has countertops to meet your vision.
            <p/>
            <hr/>

            <strong>Hardware</strong>
            <br/>
            With a wide assortment of hardware options for your home or business, Norhart Supply has the finishing touches for any room.  These finishing touches can add the final wow factor to any cabinet, cupboard, drawer or closet.  These smalls items, such as knobs on a cupboard, can really tie a room together.  Bronze, gold, silver and black knobs and handles can all give a different feel to a room, at Norhart Supply we provide a variety of options to give you the feel you’re looking for.
            <p/>
            <hr/>

            <strong>Trim</strong>
            <br/>
            Norhart Supply carries trim to add the final details to any space.  Trim comes in a variety of styles, with varying details to give any space the edge or class you are looking for.  Along with a variety of styles, trim comes in an assortment of sizes, both thickness and height.  Trim can be used for not only wall bases and ceilings but added to walls in designs to create a statement piece.
            <p/>
            <hr/>

            <strong>Paint</strong>
            <br/>
            Paint is the one material with never-ending color options.  Norhart Supply has flat, semi-gloss, high-gloss, eggshell, and satin paint in the colors you envision in your building.  Flat paint provides you with a matte finish, whereas semi-gloss or high-gloss paint provides a shine.  Eggshell paint has a somewhat matte finish with a bit of sheen.  Satin paint is great for high traffic areas and provides a slight luster effect.

          </div>
        </div>
      </div>
    </section>
  )
}

export default FinishingProductSection
