import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"
import tagManager from "../utils/tag-manager"
import useHeroImage from "../hooks/useHeroImage"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** dynamic sections */
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import FinishingProductSection from "../components/dynamic-sections/FinishingProductSection"

/** svg */
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const buildingMaterialsPage: React.FC<Props> = (props) => {
  const { titleOne, subTitleOne, imageOne, twitterCardOne, twitterOpenGraphOne } = useHeroImage()

  tagManager.pushOnce({
    event: "view-home",
  })
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/",
          name: "Norhart Supply Finishing",
          image: `${config.siteMetadata.siteUrl}/home/norhart-home-hero.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Norhart Supply | Finishing"
      description="We Provide Building Supplies For Multifamily Contractors"
      keywords=""
      imageTwitter={`${config.siteMetadata.siteUrl}${twitterCardOne}`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}${twitterOpenGraphOne}`}
      colorPalette={colorPalette}
    >

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <FinishingProductSection />

    </Layout>
  )
}

/** export */
export default buildingMaterialsPage
